import { A11yModule } from '@angular/cdk/a11y';
import { DialogModule } from '@angular/cdk/dialog';
import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import {
  FooterComponent_v2,
  HeaderComponent_v2,
  LayoutComponent_v2,
  NavDisclosureComponent_v2,
  NavIconComponent_v2,
  NavItemComponent_v2,
  NavPopoverComponent_v2,
  SidebarComponent_v2,
  SwitcherDisclosureComponent,
  SwitcherItemComponent,
  SwitcherPopoverComponent,
} from './components/layout-v2';

import {
  ConfirmComponent,
  DropdownComponent,
  FooterComponent,
  HeaderComponent,
  LayoutComponent,
  NavDisclosureComponent,
  NavIconComponent,
  NavItemComponent,
  NavPopoverComponent,
  ProgressToastComponent,
  SidebarComponent,
  ToastComponent,
} from './components';

import { ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbComponent } from './components/breadcrumb.component';
import { IconsModule } from './icons';
import { DialogService, ToastService } from './services';

@NgModule({
  imports: [
    A11yModule,
    CommonModule,
    CdkMenuModule,
    DialogModule,
    IconsModule,
    OverlayModule,
    RouterModule,
    ReactiveFormsModule,
  ],
  declarations: [
    // Version 2 components (deprecated)

    FooterComponent_v2,
    HeaderComponent_v2,
    SidebarComponent_v2,
    LayoutComponent_v2,

    NavDisclosureComponent_v2,
    NavIconComponent_v2,
    NavItemComponent_v2,
    NavPopoverComponent_v2,

    SwitcherDisclosureComponent,
    SwitcherItemComponent,
    SwitcherPopoverComponent,

    // Version 3 components

    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    LayoutComponent,

    NavDisclosureComponent,
    NavIconComponent,
    NavItemComponent,
    NavPopoverComponent,

    ConfirmComponent,
    DropdownComponent,
    ToastComponent,
    ProgressToastComponent,
    BreadcrumbComponent,
  ],
  exports: [
    DialogModule,
    CdkMenuModule,
    IconsModule,
    OverlayModule,
    FooterComponent,
    ConfirmComponent,
    DropdownComponent,
    LayoutComponent,
    LayoutComponent_v2,
    BreadcrumbComponent,
  ],
})
export class ApolloAngularModule {
  /**
   * Ensure that only one instance of the services is provided
   * Call this method in the root module of your application
   */
  static forRoot() {
    return {
      ngModule: ApolloAngularModule,
      providers: [DialogService, ToastService],
    };
  }
}
